<template>
    <div class="content content-fixed" style="padding-top: 80px; height: calc(100vh - 105px); overflow-y: scroll">
        <div class="container">
            
            <div class="row no-gutters">
        <div class="col-sm-12">

            

            <div class="table-responsive">
            
            
            <table class="table table-sm mg-b-20">
              <tbody>
                <tr>
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Улсын дугаар</th>
                  <td class="text-left">{{obj.plateNo}}</td>
                </tr>
                <tr>
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Огноо</th>
                  <td class="text-left">{{obj.date}}</td>
                </tr>
                <tr>
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Байршил</th>
                  <td class="text-left">{{obj.gateInfo}}</td>
                </tr>
                <tr>
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Хураамж</th>
                  <td class="text-left">{{formatPrice(obj.budget)}}</td>
                </tr>
                <tr v-if="showMoreInfo">
                  <th scope="row" colspan="2" class="text-center bg-gray-100 tx-color-03">Нэмэлт мэдээлэл</th>
                </tr>

                <tr v-if="showMoreInfo">
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Хаанаас</th>
                  <td class="text-left">{{ obj.startLoc }}</td>
                </tr>
                <tr v-if="showMoreInfo">
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Хаашаа</th>
                  <td class="text-left">{{ obj.endLoc }}</td>
                </tr>
                <tr v-if="showMoreInfo">
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Туулах зам (км)</th>
                  <td class="text-left">{{ obj.achaaDistance }}</td>
                </tr>
                <tr v-if="showMoreInfo">
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Нийт жин (кг)</th>
                  <td class="text-left">{{ obj.weight }}</td>
                </tr>

                <tr>
                    <td colspan="2">
                        <button @click="prePareTask()" class="btn btn-xs btn-warning">алдаа мэдээллэх</button>
                    </td>
                </tr>
              </tbody>
            </table>
            </div>
            <div v-if="showMoreInfo">
                <img :src="obj.imageLink" class="wd-100p" />
            </div>

            <div v-if="isDesktop" class="mg-b-20 pd-t-10">
                <div class="d-flex align-tems-center">
                    <div class="bd-l bd-3 bd-primary">
                        <img src="/tip.svg" class="wd-200" />
                    </div>
                    <div class="tx-16">
                        Та www.roadpay.mn системээр авто зам ашигласны төлбөрөө цаашид цахимаар төлж хэвшихийг уриалж байна. 
                        <div class="tx-color-02">
                            <br />Мөн E-Mongolia, Е-barimt  АПП-аар төлбөр төлөх боломжтой. 
                            <br />Цахим шилжилтийг дэмжиж төлбөрөө цаг хугацаанд төлж байгаа танд баярлалаа. <hr />Зам тээврийн хөгжлийн яам
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="mg-t-20 pd-t-10">
                <div class="d-flex align-tems-center">
                    <div class="bd-l bd-3 bd-primary">
                        <img src="/tip.svg" class="wd-100" />
                    </div>
                    <div class="">
                        Та www.roadpay.mn системээр авто зам ашигласны төлбөрөө цаашид цахимаар төлж хэвшихийг уриалж байна. 
                    </div>
                </div>
                
                <div class="tx-color-02">
                    <br />Мөн E-Mongolia, Е-barimt  АПП-аар төлбөр төлөх боломжтой. 
                    <br />Цахим шилжилтийг дэмжиж төлбөрөө цаг хугацаанд төлж байгаа танд баярлалаа. <hr />Зам тээврийн хөгжлийн яам
                </div>
            </div>
            
        </div>
    </div>

        </div>    
    </div>

    <!-- <div style="position: absolute; left: 10px; bottom: 10px ">
        <button class="btn btn-xs btn-warning">алдаа мэдээллэх</button>
    </div> -->

    <footer class="bd-t pd-10 bg-gray-100 pos-fixed b-0 wd-100p">
        <div class="btn-group">
        <a @click="backList()" class="btn btn-warning btn-uppercase">жагсаалт</a>
        <button v-if="notPaidCount>0" @click="backListPay()" class="btn btn-success btn-uppercase">төлөх ({{notPaidCount}}ш)</button>
        <!-- <button v-if="!obj.paid" @click="showPayment()" class="btn btn-success btn-uppercase">төлөх</button>
        <button v-else disabld class="btn btn-outline-success btn-uppercase">төлөгдсөн</button> -->
        </div>
        <!-- <button class="mg-l-auto btn btn-xs btn-warning">алдаа мэдээллэх</button> -->
    </footer>

    <div v-if="taskModelRdy" class="modal fade show" style="display: block; padding-right: 17px;; ">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content tx-14">
            <div class="modal-header">
                <h6 class="modal-title">Төрөл сонгоно уу</h6>
            </div>
            <div class="modal-body" style="overflow-y: auto; height: auto">
                <select v-model="taskModel.task" class="custom-select">
                    <option selected>-- сонгоно уу --</option>
                    <option value="Машины дугаар буруу">Машины дугаар буруу</option>
                    <option value="Зарагдсан машин">Зарагдсан машин</option>
                    <option value="Бусад">Бусад</option>
                </select>
            </div>
            <div class="modal-footer">
                <button @click="taskModelRdy=false;" type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Хаах</button>
                <button @click="postTask()" type="button" class="btn btn-primary tx-13" >Илгээх</button>
            </div>
        </div>
      </div>
    </div>

    <div v-if="taskModelRdy" class="modal-backdrop fade show"></div>

</template>
<script>
import { request } from '@/api/all';
import mainURL from "../../../config/mainUrl";
import { mapActions } from "vuex";
import { getToken } from "@/libs/util";
export default{
    data(){
        return{
            showMoreInfo: false,
            taskModel: {
                task: null,
                taskEn: null,
                // id
                // plate
                // bairshil
                // pnlt ognoo


            },
            taskModelRdy: false,
            notPaidCount: 0,
            isDesktop: '',
            obj: {}
        }
    },
    mounted(){
        this.onResize();
        window.addEventListener('resize', this.onResize, { passive: true })
        this.loadDetail();
    },
    beforeDestroy () {
        if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.onResize, { passive: true })
        }
    },
    methods:{
        ...mapActions(["handleLoading"]),
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        onResize () {
            this.isDesktop = window.innerWidth >= 600
        },
        loadDetail(){
            this.handleLoading({ isLoading: true });
            request({
                api: { method: 'GET', url: 'RoadPay/GetDetail'},
                params:{id: this.$route.params.id},
                store: this.$store
            }).then(res => {
                this.handleLoading({ isLoading: false });
                if (res !== false) {
                    // console.log('res=> ', res);
                    if(res.result!=undefined){
                        if(res.result==0){
                            this.$router.push({name: 'reg', params:{ id: res.plateNo }});
                        }else if(res.result!=undefined){
                          if(res.data.toll.showMoreInfo){
                            this.showMoreInfo= true;
                          }
                          this.obj= res.data.toll;
                          this.notPaidCount= res.data.notPaidCount;
                        }
                    }
                }else{
                    this.$router.push('/');
                }
            });
        },
        backList(){
            this.$router.push('/v/'+this.obj.plateNo+'/0');
        },
        backListPay(){
            this.$router.push('/v/'+this.obj.plateNo+'/1');
        },

        showPayment(){
            this.handleLoading({ isLoading: true });
            request({
                api: { method: 'GET', url: 'roadPay/PayByBadge'},
                params:{ mode: 1, id: null, plateNo: null  },
                store: this.$store
            }).then(res => {
                this.handleLoading({ isLoading: false });
                if (res !== false) {
                    this.pay.mode=1; 
                    this.pay.modal=true;
                }
            });
        },
        prePareTask(){
            this.handleLoading({ isLoading: true });
            request({
                api: { method: "GET", url: "Task/GetInherited" },
                store: this.$store,
                params: {
                //id: id,
                projectId: 56539,
                objectTypeId: 551,
                //userId: this.$store.state.user.userId
                }
            }).then(res => {
                this.handleLoading({ isLoading: false });
                if (res != false) {
                    //console.log(res, this.obj, '**');
                  this.taskModel = res.data.task;
                  this.taskModelRdy = true;

                  this.taskModel.i1= this.obj.id;
                  this.taskModel.st1= this.obj.plateNo;
                  this.taskModel.st2= this.obj.gateInfo;
                  this.taskModel.d1= this.obj.date;

                  this.taskModel.defaultAttachmentId= this.obj.mainImgId;
                  this.taskModel.secondAttachmentId= this.obj.plateImgId;

                }
            });
        },
        postTask(){ 
            if(this.taskModel.task==null || this.taskModel.task=='' || this.taskModel.task=='-- сонгоно уу --'){
                alert('Сонгоно уу');
            }else{
                this.handleLoading({ isLoading: true });
                request({
                    api: { method: "POST", url: "Task/PostInherited" },
                    data: {
                        model: this.taskModel
                    },
                    store: this.$store
                }).then(res => {
                    this.handleLoading({ isLoading: false });
                    if(res!=false){
                        alert('Амжилттай');
                        this.backList();
                        //console.log('res posted', res);
                    }
                });
            }
        },  
    }
}
</script>
